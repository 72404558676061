
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Choisir un contenu à traduire">
       
        <b-form
          @submit.prevent
        >
        <b-row>

          <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Langue"
            label-for="Langue"
          >
         
            <v-select
              
              v-model="selectedLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="languageChoices"
               
            />
            
          </b-form-group>
        </b-col>
        

        <b-col
          v-if="selectedLanguage"
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Type de ressource"
            label-for="Type de ressource"
          >
          
            <v-select
              
              v-model="selectedRessourceType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="ressourceTypeChoices"
             
            />
           
          </b-form-group>
        </b-col>

         <b-col v-if="selectedRessourceType"
          md="6"
          xl="4"
          class="mb-1"
        >
        <b-form-group
            label="Ressource"
            label-for="Ressource"
          >
         
            <v-select
              
              v-model="selectedRessourceId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="ressourceChoices"
             
            />
          
          </b-form-group>
        </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card title="Traduire les champs" v-if="selectedRessourceId">
       
        <b-form
          @submit.prevent
        >
        <b-row v-if="selectedRessourceType.value=='stockists'">
           <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                placeholder="Nom"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Adresse 1"
              label-for="Prix"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.address1"
                placeholder="Adresse"
              />
            </b-form-group>
           
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Adresse 2"
              label-for="Code"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.address2"
                placeholder="Adresse 2"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Ville"
              label-for="Code"
            >
              <b-form-input
              required
                id="Code"
                v-model="form.city"
                placeholder="Ville"
              />

            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Code postal"
              label-for="Code"
            >
           
              <b-form-input
              required
                id="Code"
                v-model="form.postcode"
                placeholder="Code postal"
              />
             
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Pays"
              label-for="Code"
            >
            
              <b-form-input
              required
                id="Code"
                v-model="form.country"
                placeholder="Pays"
              />

            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Téléphone"
              label-for="Code"
            >
           
              <b-form-input
              required
                id="Code"
                v-model="form.phone"
                placeholder="Téléphone"
              />
          
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Email"
              label-for="Code"
            >
           
              <b-form-input
              required
                id="Code"
                v-model="form.email"
                placeholder="Email"
              />
             
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="selectedRessourceType.value=='branches' || selectedRessourceType.value=='tints' || selectedRessourceType.value=='tint-categories'  || selectedRessourceType.value=='textures' || selectedRessourceType.value=='texture-styles'">
        <b-col
          md="6"
          xl="4"
          class="mb-1"
        >
          <b-form-group
            label="Nom"
            label-for="basicInput"
          >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                placeholder="Nom"
              />

          </b-form-group>
        </b-col>
      </b-row>

       <b-row v-if="selectedRessourceType.value=='processings' || selectedRessourceType.value=='refinements' ">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
            
                placeholder="Nom"
               
              />
           
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description"
              label-for="basicInput"
            >
            
              <b-form-input
              required
                id="basicInput"
                v-model="form.description"
                
                placeholder="Description"
                
              />
            
            </b-form-group>
          </b-col>
          
        </b-row>

        <b-row v-if="selectedRessourceType.value=='glasses'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                placeholder="Nom"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description"
              label-for="basicInput"
            >
            
              <b-form-input
              required
                id="basicInput"
                v-model="form.description"
                
                placeholder="Description"
                
              />
            
            </b-form-group>
          </b-col>

          <b-card v-if="form.type=='collaboration' && product_variations.length>0"  title="Variations">
            <b-row v-for="(variation, index) in product_variations" :key="index">
            <b-col
                md="6"
                xl="4"
                class="mb-1"
              >
              <b-form-group
                  label="Nom"
                  label-for="Nom"
                >
                  <b-form-input
                    v-model="product_variations[index].name"
                    id="Nom"
                    step="0.01"
                    placeholder="Nom de la variation"
                  >
                  </b-form-input>
                  
                </b-form-group>
              </b-col>          
            </b-row>
          </b-card>

        <b-card  title="Contenu page produit">
          <b-row>
            <b-col
              md="6"
              xl="4"
              class="mb-1"
            >
              <b-form-group
                label="Titre 1"
                label-for="basicInput"
              >
                <b-form-input
                  id="basicInput"
                  v-model="productContentForm.title1"
               
                  placeholder="Nom du produit"
                />
              </b-form-group>
            </b-col>
        
            <b-col
              md="6"
              xl="4"
              class="mb-1"
            >
              <b-form-group
                label="Texte 1"
                label-for="Texte 1"
              >
                <b-form-textarea
                  v-model="productContentForm.text1"
                  id="textarea-default"
                  placeholder="Texte 1 produit"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="6"
              xl="4"
              class="mb-1"
            >
              <b-form-group
                label="Titre 2"
                label-for="basicInput"
              >
                <b-form-input
                  id="basicInput"
                  v-model="productContentForm.title2"
  
                  placeholder="Titre 2"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              xl="4"
              class="mb-1"
            >
              <b-form-group
                label="Texte 2"
                label-for="Texte 1"
              >
          
                <b-form-textarea
                  v-model="productContentForm.text2"
                  id="textarea-default"
                  placeholder="Texte 1 produit"
                  rows="3"
                />
              
              </b-form-group>
            </b-col>
            <b-col v-if="form.type=='collaboration'"
              md="6"
              xl="4"
              class="mb-1"
            >
              <b-form-group
                label="Titre 3"
                label-for="basicInput"
              >
                <b-form-input
                  id="basicInput"
                  v-model="productContentForm.title3"
                 
                  placeholder="Titre 3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Description monture"
              label-for="Texte 1"
            >
              <b-form-textarea
                v-model="productContentForm.mount_description"
                id="textarea-default"
                placeholder="Description monture"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description dimensions"
              label-for="Texte 1"
            >
              <b-form-textarea
                v-model="productContentForm.dimensions_description"
                id="textarea-default"
                placeholder="Description dimensions"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description verres"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.lense_description"
                id="textarea-default"
                placeholder="Description verres"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Description Packaging"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.packaging_description"
                id="textarea-default"
                placeholder="Description Packaging"
                rows="3"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description charnière"
              label-for="Description charnière"
            >       
              <b-form-textarea
                v-model="productContentForm.hinge_description"
                id="textarea-default"
                placeholder="Description charnière"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 1"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.spec1"
               
                placeholder="Spec 1"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 2"
              label-for="Texte 1"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.spec2"
               
                placeholder="Spec 2"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 3"
              label-for="Photo"
            >
            <b-form-input
                id="basicInput"
                v-model="productContentForm.spec3"
           
                placeholder="Spec 3"
              />
            </b-form-group>
          </b-col>
        </b-row>
    </b-card>
          
        </b-row>

      </b-form>
     

    </b-card>
  <b-button
     @click="saveTranslation"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest, Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'TranslationForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BCardText, 
    BMedia, 
    BImg,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          file: null,
          glass:null,
          languages:null,
          ressourceTypeChoices:[
            {
              title:'Lunette',
              value:'glasses'
            },
            {
              title:'Point de vente',
              value:'stockists'
            },
            {
              title:'Teinte',
              value:'tints'
            },
            {
              title:'Texture',
              value:'textures'
            },
            {
              title:'Branche',
              value:'branches'
            },
            {
              title:'Style de texture',
              value:'texture-styles'
            },
            {
              title:'Catégorie de teinte',
              value:'tint-categories'
            },
            {
              title:'Affinement',
              value:'refinements'
            },
            {
              title:'Traitement',
              value:'processings'
            }
          ],
          ressourceChoices:[],
          languageChoices:[],
          edition:false,
          loading:true,
          selectedLanguage:null,
          selectedRessourceType:null,
          selectedRessourceId:null,
          ressources:null,
          translated:false,
          form:{},
          productContentForm:{},
          product_variations:[]
      }
  },
  watch: { 
    selectedRessourceType: async function() { // watch it
    
      await this.getRessources()
      
    },
    selectedRessourceId: async function() { // watch it
      
      await this.getTranslations()
      
    }
  },
  methods:{
    async getRessources() {
      let ressources = await APIRequest.list(this.selectedRessourceType.value)
      this.ressources=ressources[0];
      this.ressourceChoices=Utils.formatSelect(this.ressources,'name','id')
      
    },
     async getTranslations() {
      this.translated=false
      this.form={}
      this.productContentForm={}
      
      let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'name')
      let field_content=field[0];
      if(field_content.length>0)
      {
        this.translated=true
        this.form.name=field_content[0].translation
      }
      else
      {
        this.form.name=''
      }

      if(this.selectedRessourceType.value=="refinements" || this.selectedRessourceType.value=="processings" || this.selectedRessourceType.value=="glasses" )
      {
        let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'description')
        let field_content=field[0];
        if(field_content.length>0)
        {
          this.form.description=field_content[0].translation
        }
        else
        {
          this.form.description=''
        }
      }

      if(this.selectedRessourceType.value=="stockists" )
      {
        let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'address1')
        let field_content=field[0];
        if(field_content.length>0)
        {
          this.form.address1=field_content[0].translation
        }
        else
        {
          this.form.address1=''
        }
        field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'address2')
        field_content=field[0];
        if(field_content.length>0)
        {
          this.form.address2=field_content[0].translation
        }
        else
        {
          this.form.address2=''
        }
         field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'city')
         field_content=field[0];
        if(field_content.length>0)
        {
          this.form.city=field_content[0].translation
        }
        else
        {
          this.form.city=''
        }
         field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'postcode')
         field_content=field[0];
        if(field_content.length>0)
        {
          this.form.postcode=field_content[0].translation
        }
        else
        {
          this.form.postcode=''
        }

         field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'country')
         field_content=field[0];
        if(field_content.length>0)
        {
          this.form.country=field_content[0].translation
        }
        else
        {
          this.form.country=''
        }

         field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'phone')
         field_content=field[0];
        if(field_content.length>0)
        {
          this.form.phone=field_content[0].translation
        }
        else
        {
          this.form.phone=''
        }

         field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'email')
         field_content=field[0];
        if(field_content.length>0)
        {
          this.form.email=field_content[0].translation
        }
        else
        {
          this.form.email=''
        }
      }

      if(this.selectedRessourceType.value=="glasses" )
      {
        let glass = await APIRequest.detail("glasses",this.selectedRessourceId.value);
        this.glass=glass
        this.form.type=glass.type

        let field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'title1')
        let field_content=field[0];
        if(field_content.length>0)
        {
          this.productContentForm.title1=field_content[0].translation
        }
        else
        {
          this.productContentForm.title1=''
        }
        field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'text1')
        field_content=field[0];
        if(field_content.length>0)
        {
          this.productContentForm.text1=field_content[0].translation
        }
        else
        {
          this.productContentForm.text1=''
        }
        field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'title2')
        field_content=field[0];
        if(field_content.length>0)
        {
          this.productContentForm.title2=field_content[0].translation
        }
        else
        {
          this.productContentForm.title2=''
        }
        field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'text2')
        field_content=field[0];
        if(field_content.length>0)
        {
          this.productContentForm.text2=field_content[0].translation
        }
        else
        {
          this.productContentForm.text2=''
        }

        if(this.glass.type=="collaboration")
        {
          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'title3')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.title3=field_content[0].translation
          }
          else
          {
            this.productContentForm.title3=''
          }
          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'mount_description')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.mount_description=field_content[0].translation
          }
          else
          {
            this.productContentForm.mount_description=''
          }

          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'dimensions_description')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.dimensions_description=field_content[0].translation
          }
          else
          {
            this.productContentForm.dimensions_description=''
          }

          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'lense_description')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.lense_description=field_content[0].translation
          }
          else
          {
            this.productContentForm.lense_description=''
          }

          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'packaging_description')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.packaging_description=field_content[0].translation
          }
          else
          {
            this.productContentForm.packaging_description=''
          }

          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'hinge_description')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.hinge_description=field_content[0].translation
          }
          else
          {
            this.productContentForm.hinge_description=''
          }

          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'spec1')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.spec1=field_content[0].translation
          }
          else
          {
            this.productContentForm.spec1=''
          }
          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'spec2')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.spec2=field_content[0].translation
          }
          else
          {
            this.productContentForm.spec2=''
          }
          field = await APIRequest.search_translation(this.selectedLanguage.value,"product-contents", glass.product_content.id, 'spec3')
          field_content=field[0];
          if(field_content.length>0)
          {
            this.productContentForm.spec3=field_content[0].translation
          }
          else
          {
            this.productContentForm.spec3=''
          }

          let product_variations = this.glass.product_variations
     
          if(product_variations.length>0)
          {
            this.product_variations=[]
            for(let p=0;p<product_variations[0].product_variation_items.length;p++)
            {
              let item={}
              item.id=product_variations[0].product_variation_items[p].id
              field = await APIRequest.search_translation(this.selectedLanguage.value,"product-variation-items", item.id, 'name')
              field_content=field[0];
              if(field_content.length>0)
              {
                item.name=field_content[0].translation
              }
              else
              {
               item.name=''
              }
              this.product_variations.push(item)
            }
          }
        }
      }

      this.$forceUpdate()
      
    },
   async getLanguages() {
      let languages = await APIRequest.list('languages')
      this.languages=languages[0];
      for (let i=0;i<this.languages.length;i++)
      {
        if(this.languages[i].default_language==true)
        {
          this.languages.splice(i, 1);
        }
      }
      this.languageChoices=Utils.formatSelect(this.languages,'name','id')
      
    },
    async saveTranslation()
    {
      
        this.loading=true
        try {
          
          let Parameters={}
          Parameters.language={}
          Parameters.language.id=this.selectedLanguage.value
          Parameters.ressource_type=this.selectedRessourceType.value
          Parameters.ressource_id=this.selectedRessourceId.value
          Parameters.translation=this.form.name
          Parameters.field="name"

          if(this.translated==true)
          {
            let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'name')
            let field_content=field[0];
            let id_translation=field_content[0].id
            await APIRequest.update('translations',id_translation,Parameters)
            this.form.name=field_content[0].translation
          }
          else
          {
            await APIRequest.create('translations',Parameters)
          }

          if(this.selectedRessourceType.value=="refinements" || this.selectedRessourceType.value=="processings" || this.selectedRessourceType.value=="glasses" )
          {
            let Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.description
            Parameters.field="description"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'description')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }
          }

          if(this.selectedRessourceType.value=="stockists")
          {
            let Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.address1
            Parameters.field="address1"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'address1')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.address2
            Parameters.field="address2"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'address2')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

             Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.city
            Parameters.field="city"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'city')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

             Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.postcode
            Parameters.field="postcode"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'postcode')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

             Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.country
            Parameters.field="country"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'country')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.phone
            Parameters.field="phone"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'phone')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type=this.selectedRessourceType.value
            Parameters.ressource_id=this.selectedRessourceId.value
            Parameters.translation=this.form.email
            Parameters.field="email"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, this.selectedRessourceType.value, this.selectedRessourceId.value, 'email')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
              this.form.name=field_content[0].translation
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }
          }

          if(this.selectedRessourceType.value=="glasses" )
          {
            let Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type="product-contents"
            Parameters.ressource_id=this.glass.product_content.id
            Parameters.translation=this.productContentForm.title1
            Parameters.field="title1"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'title1')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
      
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type="product-contents"
            Parameters.ressource_id=this.glass.product_content.id
            Parameters.translation=this.productContentForm.text1
            Parameters.field="text1"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'text1')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
      
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            
            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type="product-contents"
            Parameters.ressource_id=this.glass.product_content.id
            Parameters.translation=this.productContentForm.title2
            Parameters.field="title2"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'title2')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
      
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            Parameters={}
            Parameters.language={}
            Parameters.language.id=this.selectedLanguage.value
            Parameters.ressource_type="product-contents"
            Parameters.ressource_id=this.glass.product_content.id
            Parameters.translation=this.productContentForm.text2
            Parameters.field="text2"

            if(this.translated==true)
            {
              let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'text2')
              let field_content=field[0];
              let id_translation=field_content[0].id
              await APIRequest.update('translations',id_translation,Parameters)
      
            }
            else
            {
              await APIRequest.create('translations',Parameters)
            }

            if(this.glass.type=="collaboration")
            {
              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.title3
              Parameters.field="title3"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'title3')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
        
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.mount_description
              Parameters.field="mount_description"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'mount_description')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.dimensions_description
              Parameters.field="dimensions_description"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'dimensions_description')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.lense_description
              Parameters.field="lense_description"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'lense_description')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.packaging_description
              Parameters.field="packaging_description"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'packaging_description')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.hinge_description
              Parameters.field="hinge_description"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'hinge_description')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.spec1
              Parameters.field="spec1"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'spec1')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.spec2
              Parameters.field="spec2"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'spec2')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              Parameters={}
              Parameters.language={}
              Parameters.language.id=this.selectedLanguage.value
              Parameters.ressource_type="product-contents"
              Parameters.ressource_id=this.glass.product_content.id
              Parameters.translation=this.productContentForm.spec3
              Parameters.field="spec3"

              if(this.translated==true)
              {
                let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-contents", this.glass.product_content.id, 'spec3')
                let field_content=field[0];
                let id_translation=field_content[0].id
                await APIRequest.update('translations',id_translation,Parameters)
              }
              else
              {
                await APIRequest.create('translations',Parameters)
              }

              if(this.product_variations.length>0)
              {
              
                for(let p=0;p<this.product_variations.length;p++)
                {
                  Parameters={}
                  Parameters.language={}
                  Parameters.language.id=this.selectedLanguage.value
                  Parameters.ressource_type="product-variation-items"
                  Parameters.ressource_id=this.product_variations[p].id
                  Parameters.translation=this.product_variations[p].name
                  Parameters.field="name"
                  if(this.translated==true)
                  {
                    let field = await APIRequest.search_translation(this.selectedLanguage.value, "product-variation-items", this.product_variations[p].id, 'name')
                    let field_content=field[0];
                    let id_translation=field_content[0].id
                    await APIRequest.update('translations',id_translation,Parameters)
                  }
                  else
                  {
                    await APIRequest.create('translations',Parameters)
                  }
                  
                }
              }
            }
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La traduction a bien été prise en compte !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'languages'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
       
      
    },
    async editLanguage()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
      this.loading=true
        try {
        
          await APIRequest.update('languages',this.language.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'La langue a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'languages'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    
     
      
  },
  async mounted(){
    await this.getLanguages()
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>